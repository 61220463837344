<template>
  <div class="yj-frame-main">
    <div class="up-banner">
      <div class="product">
        <h3>
          <typed-ment id="yjtsy" strings="一江通水运智慧物流服务平台"></typed-ment>
        </h3>
        <img class="proImg" src="../../assets/image/product/001.png" alt="">
        <h4>联系我们</h4>
        <contact-us></contact-us>
      </div>
    </div>
  </div>
</template>
<script>
import contactUs from '@/components/contactUs.vue'
export default {
  name: "yjtsy",
  components: {
    contactUs
  },
  data(){
    return {
    
    }
  },
  mounted() {

  },
  methods: {
   
  },
}
</script>
<style lang="scss" scoped>
.up-banner{
  background:url("~@/assets/image/product/yjt.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
	width: 100%;
  height: calc(100vh - 80px);
  .proImg{
    width: 450px;
  }
}
</style>